html, body {
  font-size: 10px;
  font-family: $FontWeb;

  p,li {
    font-size: 1.4rem;
  }

  .wrapper {
    max-width: $wrapper-width;
    margin: 0 auto;
    display: block;
  }

  .center-page{
    z-index: -2;
    padding: 0 20px;
    //width: $wrapper-width;
    margin:0 auto;
    display: block;
    background-color: white;
    box-shadow: 0 0 1px grey;

    h1,h2{
      margin-top: 0;
    }
  }

  .boton{
    border:none;
    border-radius: 0;
    padding-left: 25px;
    padding-right: 25px;
    margin: 10px 0;
    box-shadow: none;
    background: $azul;
    transition: all .3s;
    
    &:hover{
      background: darken($azul,10);
    }
  }

  .btn{
    border-radius: 0;
    border:none;
  }

  a[data-title],
  button[data-title]
  {
    @media (min-width: 800px)
    {
      position: relative;

      &:hover:after
      {
        content: attr(data-title);
        padding: 4px 8px;
        color: #333333;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 110%;
        z-index: 20;
        white-space: nowrap;
        -moz-border-radius: 3px;
        -webkit-border-radius: 3px;
        border-radius: 3px;
        background: white;
        -moz-box-shadow: 0 0 2px #222222;
        -webkit-box-shadow: 0 0 2px #222222;
        box-shadow: 0 0 2px #222222;
        //background-image: -moz-linear-gradient(top, #eeeeee, #cccccc);
        //background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #eeeeee), color-stop(1, #cccccc));
        //background-image: -webkit-linear-gradient(top, #eeeeee, #cccccc);
        //background-image: -moz-linear-gradient(top, #eeeeee, #cccccc);
        //background-image: -ms-linear-gradient(top, #eeeeee, #cccccc);
        //background-image: -o-linear-gradient(top, #eeeeee, #cccccc);
      }
    }
  }

}