footer {
  background: $azul;
  padding: 28px 0;

  @include Mobile{
    text-align: center;
  }
  svg {
    height: auto;
    max-width: 215px;
  }

  ul.data-contact {
    float: right;

    @include Mobile{
      float: none;
      padding: 20px;
    }

    li {
      //text-align: right;
      color: white;
      font-size: 1.3rem;

      &.operado{
        a{
          svg g{
            transition: all .3s;
          }
          &:hover{
            svg g, svg path {
              fill:darken($azul,20);
            }
          }
        }
      }

      a{
        color:white;
      }

    }
  }
}