$break-mobile:480px;
$break-tablet:780px;

@mixin Mobile() {
  @media all and (max-width: $break-mobile) {
    @content;
  }
}

@mixin Tablet() {
  @media (max-width: $break-tablet) {
    @content;
  }
}

@mixin line($time: 0.5s) {
  transform: scaleX(0);
  transform-origin: 100% 0;
  transition: transform $time;
}

@mixin line-hover() {
  transform: scaleX(1);
  transform-origin: 0 0;
}