section.servicios-section {
  .cont {
    padding: 25px 0;
    //border: 1px solid red;
    .col-detail{
      overflow-x: hidden;
      h2{
        text-transform: uppercase;
        color:$naranja;
        font-size: 2.3rem;
        margin-top: 0;
        font-weight: 700;
      }
      h3{
        text-transform: uppercase;
        color:grey;
        font-weight: 700;
        font-size: 1.5rem;
        margin-bottom: 5px;
      }
    }

    ul.sidebar {
      li{
        button{
          color:black;
          font-size: 1.3rem;
          position: relative;
          padding: 5px 0;
          transition: color .4s;
          font-weight: 700;

          &:after{
            content: '';
            width: 100%;
            height: 1px;
            position: absolute;
            left: 0;
            bottom: 0;
            background: $azul;
            @include line();
          }

          &:hover{
            text-decoration: none;
            color:$azul;
            &:after{
              @include line-hover();
            }
          }

          &.active-b{
            color:$azul;

            &:after{
              transform: scale(1) !important;
              //@include line();
            }
          }
        }
      }
    }

  }


}