section.slide-container {
  position: relative;

  .next-slider, .prev-slider {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    //color:$azul;
    height: 50px;
    width: 50px;
    box-shadow: none;
    border: none;
    color: white;

    i {
      font-size: 3em;
      color: rgba(128, 128, 128, 0.56);
      //box-shadow: 0 0 2px grey;
    }
  }

  button.next-slider {
    right: 0;
  }

  button.prev-slider {
    left: 0;
  }

  div.slider {
    width: 100%;
    //border:1px solid red;

    &.slick-slider {
      margin-bottom: 0;
    }
  }

}
