header.header {
  display: block;
  background: $azul;
  width: 100%;
  transition: all .3s;
  position: relative;

  .pre-header {
    max-width: $wrapper-width;
    margin: 0 auto;
    display: flex;
    //border:1px solid red;
    position: relative;

    > div, ul {
      flex: 1 1;
    }
    ul {
      position: absolute;
      right: 0;
      text-align: right;
      margin-right: 10px;

      li {
        display: inline-block;
        padding-right: 5px;
        padding-top: 7px;

        &:last-child{
          padding-right: 0;
        }

        a {
          color: white;
          position: relative;

          i{
            font-size: 1.2em;
          }
        }
      }
    }
  }

  .logo {
    display: flex;
    justify-items: center;
    justify-content: center;
    padding: 25px 0;

    svg {
      max-height: 55px;
    }
  }

  nav {
    width: 100%;
    margin: 0 auto;
    background: $naranja;

    ul {
      display: flex;
      max-width: $wrapper-width;
      margin: 0 auto;

      li {
        font-family: $FontWeb;
        flex: 1 1;
        text-align: center;

        a {
          text-decoration: none;
          display: block;
          padding: 10px;
          color: white;
          text-transform: uppercase;
          font-size: 1.2rem;
          position: relative;
          text-shadow: none;
          font-weight: 600;

          &.active {
            background: darken($naranja, 10);
            color: white;
          }

          &:after {
            content: '';
            width: 100%;
            height: 2px;
            background: $azul;
            position: absolute;
            bottom: 0;
            left: 0;
            transition: all .3s;
            transform: scale(0);
          }

          &:hover {
            //background: white;
            color: white;
            text-shadow: 0 0 1px black;
            //font-weight: 800;

            &:after {
              transform: scale(1);
            }
          }
        }
      }
    }

  }

  &.header-fix {
    position: fixed;
    top: -200%;
    left: 0;
    z-index: 888;

    &.visible {
      top: 0;
    }

    .logo {
      padding: 10px;

      svg {
        max-height: 25px;
      }
    }
  }

}

nav.mobile {
  position: fixed;
  left: 0;
  top: -100%;
  transition: all .3s;
  width: 100%;
  height: 100%;
  //display: block;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  padding: 20px;
  background: $naranja;

  &.activo {
    top: 0;
  }

  ul {
    display: flex;
    flex-direction: column;

    li {
      width: 100%;
    }
  }

  .logo-mobile {
    padding: 0 20px;
    margin-bottom: 30px;

    svg {
      display: block;
      margin: 0 auto;
      width: 80%;
      height: auto;
    }
  }
}

.active-mobile {
  a {
    color: white;
    display: block;
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
    //border:1px solid red;
    padding: 15px 0;
    background: $naranja;
    text-transform: uppercase;
  }
}

#what{
  .btn-block{
    margin-top: 5px;
    text-transform: uppercase;
  }
}