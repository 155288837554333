section.tips-section {

  .video {
    margin: 0 auto;
    display: block;
    width: 100%;
    padding: 30px 0;

    a.open-video {
      position: relative;
      display: block;

      &:before {
        opacity: 0;
        transition: opacity .4s;
        width: 100%;
        height: 100%;
        position: absolute;
        background: rgba(black, .4);
        left: 0;
        top: 0;
        content: '\f01d';
        font-family: 'FontAwesome', sans-serif;
        text-align: center;
        color: white;
        display: flex;
        font-size: 6rem;
        justify-content: center;
        align-items: center;
        z-index: 400;
      }

      &:hover {
        &:before {
          opacity: 1;
        }
      }
    }
    h2, h3 {
      text-transform: uppercase;
    }

    video {
      box-shadow: 0 0 2px grey;
      max-width: 780px;
      margin: 0 auto;
      display: block;
      width: 100%;
      position: fixed;
      left: 50%;
      top: -200%;
      transition: all .3s;
      transform: translate(-50%, 0);

      &.video-active {
        z-index: 600;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .back-video {
    opacity: 0;
    transform: all .4s;
    position: fixed;
    background: rgba(black, .6);
    width: 100%;
    height: 100%;
    z-index: 500;
    left: 0;
    top: 0;
    display: none;
  }
}