section.home-section {
  margin-top: 0;

  .row{
    padding-top: $padding-sections;
  }

  svg {
    width: 100%;

    g, path {
      fill: $azul;
    }
  }

}