//@import "compass";

.cover {
  display: flex;
  justify-content: flex-end;
  justify-items: flex-end;
  align-items: center;
  align-content: center;
  height: 150px;
  position: relative;

  @include Mobile{
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
  }
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1e5799+0,236aaf+74&0+0,0.67+93 */
  background: -moz-linear-gradient(top, rgba(30,87,153,0) 0%, rgba(35,106,175,0.53) 74%, rgba(35,106,175,0.67) 93%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(30,87,153,0) 0%,rgba(35,106,175,0.53) 74%,rgba(35,106,175,0.67) 93%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(30,87,153,0) 0%,rgba(35,106,175,0.53) 74%,rgba(35,106,175,0.67) 93%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001e5799', endColorstr='#ab236aaf',GradientType=0 ); /* IE6-9 */

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
    //background-size: 100%;
    background-position: center center;
    background-image: url("../img/site/back.jpg");
  }

  h1 {
    //bottom: 10%;
    //position: absolute;
    //left: 2%;
    color: white;
    text-transform: uppercase;
    padding-right: 20px;
    font-weight: 700;
    text-shadow: 0 0 1px black;
    display: inline-block;
    margin: 0;
    font-size: 3rem;

    @include Mobile{
      text-align: center;
    }

  }
}