section.neumaticos-section {
  &.center-page {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  p{
    font-size: 1.3rem;
  }
  img {
    display: block;
    margin: 0 auto;
  }

  .img-container{
    padding: 25px 15px;
  }

  .item-neumatico {
    display: flex;
    padding: 20px;

    h4 {
      font-size: 1.5rem;
      color: $naranja;
      text-transform: uppercase;
      font-weight: 700;
    }

    p{
      font-weight: 700;
    }
  }

  h2 {
    color: $naranja;
    text-transform: uppercase;
    padding-top: 15px;
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 15px;
    position: relative;
    text-align: right;
    font-style: italic;

    &:after {
      content: '';
      width: 100%;
      height: 1px;
      background: $naranja;
      position: absolute;
      display: block;
      bottom: -8px;

    }
  }

  h3 {
    font-size: 1.9rem;
    font-weight: 700;
  }
}