body, html {
  overflow-x: hidden;

  a, button {
    outline: none;

    &:focus, &:active, &:visited {
      outline: none !important;
      text-decoration: none !important;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  a {
    text-decoration: none;
  }
}