section.contact-section{
  .row{
    padding: $padding-sections;
  }
  .vias {
    .map{
      padding: 1.2rem 0;
      iframe{
        padding: 5px;
        background: white;
        box-shadow: 0 0 2px grey;
        width: 100%;
      }
    }
  }
  form{
    input,textarea{
      box-shadow: none;
      border:none;
      border-bottom: 1px solid $azul;
      border-radius: 0;

      &:focus,&:active{
        box-shadow: none;
      }
    }
  }

  #res{
    font-size: 1.4rem
  }
}